import React, { useEffect, useState } from "react";
import "./index.css";
import logo from "../assets/images/pbr_logo.png";
import thumbs from "../assets/images/thumbs.png";
import profileDefault from "../assets/images/profile_default.png";
import axios from "axios";
import {
  redirect,
  useHistory,
  useLocation,
  useNavigate,
} from "react-router-dom";
import moment from "moment";
const MeditationInformation = (props) => {
  const navigate = useNavigate();
  const [saveState, setSaveState] = useState({});
  const [loader, setLoader] = useState(true);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState(
    "Invalid Url. Please opened the link shared from the PBR App."
  );
  const { state } = useLocation();
  useEffect(() => {
    async function start() {
      let id = window.location.search;
      let decodedUrl = decodeURIComponent(id);
      let check = decodedUrl?.split("?PARAMETER=")[1];
      if (check) {
        const subCheck = check?.split("&utm_source");
        if (subCheck) {
          check = subCheck[0];
        }
        let decodeUri = decodeURIComponent(check);
        if (subCheck) decodeUri = decodeUri.replace(/ /g, "+");
        if (decodeUri.indexOf("'") > -1 || decodeUri.indexOf('"') > -1) {
          decodeUri = decodeUri.replace(/["']/g, "");
        } else {
          decodeUri = JSON.parse(decodeUri);
        }
        console.log(decodeUri);
        const res = await axios
          .post(`https://api.pausebreathereflect.com/shareable-track`, {
            id: decodeUri,
          })
          .catch((i) => {
            setError(true);
            setLoader(false);
          });
        setTimeout(() => {
          setLoader(false);
        }, 1500);
        setSaveState(res?.data?.data);
      } else {
        window.location.replace(
          "https://www.pausebreathereflect.com/pages/pbrapp"
        );
      }
    }
    start();
  }, []);

  if (loader)
    return (
      <div
        style={{ display: "flex", justifyContent: "center" }}
        className="pbr-block"
      >
        <div className="loader"></div>
      </div>
    );

  if (error)
    return (
      <div
        style={{ display: "flex", justifyContent: "center" }}
        className="pbr-block"
      >
        <p style={{ color: "white", alignSelf: "center", textAlign: "center" }}>
          {errorMessage}
        </p>
      </div>
    );

  return (
    <div className="pbr-block">
      <div className="pbr-content">
        <div className="pbr-content-box">
          <div className="pbr-logo">
            <img src={logo} width="89" />
          </div>
          <div className="pbr-content-text">
            {state?.completed ? (
              <p>
                I hope this was helpful to you. You can join the Pause Breathe
                Reflect community by downloading the app.
              </p>
            ) : (
              <p>
                Click the play button to listen now. If you wish to bring
                mindfulness to your everyday moments, stress less, and live
                more, please download the app from the links below.
              </p>
            )}
          </div>
          <div
            onClick={() => {
              navigate("/track", { state: saveState });
            }}
            className="video-thumbs"
          >
            <i className="playbutton"></i>
            <img
              src={saveState?.image}
              style={{
                objectFit: "cover",
              }}
              width="400"
              height="238"
              alt="thumbs"
            />
          </div>
          <div className="profile-box">
            <div className="profile-box-content">
              {/* <img src={profileDefault} width="20px" alt="profile_default" /> */}
              <img src={profileDefault} width="20px" alt="profile_default" />
              <label>{saveState?.title}</label>
              <p>by Michael O Brien</p>
              <span>
                <i></i>
                {parseInt(saveState?.duration / 60).toLocaleString("en-US", {
                  minimumIntegerDigits: 2,
                  useGrouping: false,
                })}
                :
                {parseInt(saveState?.duration % 60).toLocaleString("en-US", {
                  minimumIntegerDigits: 2,
                  useGrouping: false,
                })}
              </span>
            </div>
          </div>

          <div className="download-box">
            <a href="https://play.google.com/store/apps/details?id=com.pbr.app&hl=en_US&gl=US">
              <span className="google-play-button"></span>
            </a>
            <a href="https://apps.apple.com/us/app/pause-breathe-reflect/id1619046814">
              <span className="app-store-button"></span>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MeditationInformation;
