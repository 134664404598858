import logo from "../assets/images/PBR.svg";
import Forward from "../assets/images/forward.svg";
import Backward from "../assets/images/backward.svg";
import IPhoneFrame from "../assets/images/iPhoneFrame.png";
import "./index.css";
import ReactPlayer from "react-player";
import ReactAudioPlayer from "react-audio-player";
import { BsFillPlayFill, BsFillPauseFill } from "react-icons/bs";
import { useEffect, useRef, useState } from "react";
import moment from "moment";
import "bootstrap/dist/css/bootstrap.min.css";
import Modal from "react-bootstrap/Modal";

import { useLocation, useNavigate } from "react-router-dom";
function MeditationTrack(props) {
  const { state } = useLocation();
  const [play, setPlay] = useState(true);
  const [title, setTitle] = useState(state?.title);
  const [substitle, setSubtitle] = useState(state?.author);
  const [backgroundImage, setBackgroundImage] = useState(state?.image);
  const audioRef = useRef();
  const [remainingTime, setRemainingTime] = useState(0);
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const [openOnce, setOpenOnce] = useState(3);
  const handleClose = () => {
    setShow(false);
  }
  const handleShow = () => setShow(true);
  const redirectToAppStore = () => {
    // console.log("object");
    // const androidLink =
    //   "https://play.google.com/store/apps/details?id=com.pbr.app&hl=en_US&gl=US";

    // const iosLink =
    //   "https://apps.apple.com/us/app/pause-breathe-reflect/id1619046814";

    // const userAgent = navigator.userAgent;

    // if (/android/i.test(userAgent)) {
    //   window.location.href = androidLink;
    // } else if (/iPad|iPhone|iPod/i.test(userAgent)) {
    //   window.location.href = iosLink;
    // }
    window.location.href = "https://flowcode.com/p/hUD16YHd4?fc=0";
  };

  useEffect(() => {
    audioRef.current.play();
  }, []);
  useEffect(() => {
    if (
      parseInt(audioRef.current.duration) -
      parseInt(audioRef.current.currentTime) <=
      15 &&
      openOnce == 15
    ) {
      setOpenOnce(false);
      handleShow();
    }
    if (
      parseInt(audioRef.current.currentTime) >=
      3 &&
      openOnce == 3
    ) {
      setOpenOnce(15);
      handleShow();
    }
    const subscribe = audioRef.current.addEventListener("timeupdate", () => {
      if (audioRef.current.currentTime != audioRef.current.duration) {
        let player = audioRef.current;
        setRemainingTime(
          parseInt(player.duration) - parseInt(player.currentTime)
        );
      } else {
        navigate("/", { replace: true, state: { completed: true } });
      }
    });
    return subscribe;
  }, [audioRef.current?.currentTime]);

  return (
    <div>
      <div className="track-main-app">
        <img className="img" src={backgroundImage}></img>
        <div className="imgOverlay"></div>
        <img className="frame" src={IPhoneFrame}></img>
        <div>
          <div className="trackheader">
            <div className="header">
              <img src={logo} className="logo" alt="PBR Logo" />
            </div>
            <div className="spaceHeight5"></div>
            <div className="header">
              <span className="headerTitle">{title}</span>
              <div className="spaceHeight5"></div>
              <span className="headerSubtitle">{substitle}</span>
            </div>
          </div>
          <div className="spaceHeight200"></div>

          <div className="trackfooter-controls">
            {parseInt(remainingTime / 60).toLocaleString("en-US", {
              minimumIntegerDigits: 2,
              useGrouping: false,
            })}
            :
            {parseInt(remainingTime % 60).toLocaleString("en-US", {
              minimumIntegerDigits: 2,
              useGrouping: false,
            })}
            <div className="spaceHeight5"></div>
            <div className="spaceHeight5"></div>
            <div className="controllerOuterContainer">
              <img
                onClick={() => {
                  audioRef.current.currentTime =
                    audioRef.current?.currentTime - 15;
                }}
                src={Backward}
                className="App-logo"
                alt="logo"
              />
              <div className="spaceWidth3"></div>
              <div
                onClick={() => {
                  setPlay((prevVal) => {
                    if (prevVal == false) {
                      audioRef.current.play();
                    } else {
                      audioRef.current.pause();
                    }
                    return !prevVal;
                  });
                }}
                className="controllerContainer"
              >
                {!play ? (
                  <BsFillPlayFill size={"70px"} color="white" />
                ) : (
                  <BsFillPauseFill size={"70px"} color="white" />
                )}
              </div>
              <div className="spaceWidth3"></div>
              <img
                onClick={() => {
                  if (remainingTime > 18)
                    audioRef.current.currentTime =
                      audioRef.current?.currentTime + 15;
                }}
                src={Forward}
                className="App-logo"
                alt="logo"
              />
            </div>
            <audio
              ref={audioRef}
              min="0"
              max={state?.duration}
              step="1"
              src={state?.audio}
            ></audio>
            {/* <ReactAudioPlayer
          src="https://d3izw5wl1p5t0h.cloudfront.net/tracks/Five+Minute+Meditations/5+Minute+After+a+Long+Day.mp3"
          autoPlay={true}
          controls
          muted={false}
          ref={player}
        /> */}
          </div>
        </div>
        <div className="spaceHeight5"></div>
        <div className="downloadBtnContainer" onClick={redirectToAppStore}>
          <button className="downloadBtn">DOWNLOAD</button>
        </div>
      </div>
      <Modal
        className="meditations-modal"
        show={show}
        onHide={handleClose}
        backdrop={false}
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <p>
            Try guided meditations with the <b>Pause Breathe Reflect App</b>.
          </p>
        </Modal.Body>
        <Modal.Footer onClick={redirectToAppStore}>
          <a
            href="https://flowcode.com/p/hUD16YHd4?fc=0"
            variant="primary"
            onClick={handleClose}
            className="download-app-button"
          >
            Download App
          </a>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default MeditationTrack;
