import { Navigate, Route, Router, useRoutes } from "react-router-dom";
import MeditationInformation from "../meditationInformation";
import MeditationTrack from "../meditationTrack";

const AppRoutes = () => {
  let routes = useRoutes([
    { path: "*", element: <MeditationInformation /> },
    { path: "/track", element: <MeditationTrack /> },
  ]);
  return routes;
};

export default AppRoutes;
