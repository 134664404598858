import logo from "./PBR.svg";
import Forward from "./forward.svg";
import Backward from "./backward.svg";
import "./App.css";
import ReactPlayer from "react-player";
import ReactAudioPlayer from "react-audio-player";
import { BsFillPlayFill, BsFillPauseFill } from "react-icons/bs";
import { useEffect, useRef, useState } from "react";
import moment from "moment";
function App(props) {
  var trackFlag = true;
  let trackCountFlag = true;
  const player = useRef();
  const [play, setPlay] = useState(false);
  const [title, setTitle] = useState("");
  const [substitle, setSubtitle] = useState("");
  const [backgroundImage, setBackgroundImage] = useState("");
  const audioRef = useRef(null);
  const [duration, setDuration] = useState(0);
  const [alreadyPassedTheTime, setAlreadyPassedTheTime] = useState(false);
  const [trackTimer, setTrackTime] = useState(2);
  const [track90, setTrack90] = useState(0);
  const timer = useRef();

  useEffect(() => {
    let url = new URLSearchParams(window.location.search).get("PARAMETER");
    const parsedUrl = JSON.parse(url);
    // let temptitle = url?.title;
    // let tempsubtitle = new URLSearchParams(window.location.search).get(
    //   "subtitle"
    // );
    // let tempbackground_image = new URLSearchParams(window.location.search).get(
    //   "background_image"
    // );
    // let tempaudio_url = new URLSearchParams(window.location.search).get(
    //   "audio_url"
    // );
    setTitle(parsedUrl?.title);
    setSubtitle(parsedUrl?.author);
    setBackgroundImage(parsedUrl?.image);
    setTrack90((parsedUrl?.duration * 90) / 100);
    setDuration((parsedUrl?.duration * 90) / 100);
    audioRef.current = new Audio(parsedUrl?.audio);
  }, []);

  const startTimer = () => {
    const id = setInterval(() => {
      setDuration((prevDuration) => {
        return prevDuration - 1;
      });
    }, 1000);
    timer.current = id;
  };

  const stopTimer = () => {
    clearInterval(timer.current);
  };
  // useEffect(() => {
  //   if (audioRef.current.onTimeUpdate()) {
  //     audioRef.current.onTimeUpdate((i) => {
  //       console.log(i);
  //     });
  //   }
  // }, []);

  // useEffect(() => {
  //   if (audioRef.current?.currentTime.toFixed(1) >= track90 && trackFlag) {
  //     trackFlag = false;
  //     alert("ended");
  //   }
  //   if (duration <= audioRef.current?.currentTime.toFixed(0)) {
  //     // TrackPlayer.pause();
  //     setAlreadyPassedTheTime(true);
  //     let tempRef = setInterval(() => {
  //       setTrackTime((pre) => {
  //         if (pre == 0) {
  //           alert("ended");
  //           clearInterval(tempRef);
  //           return 0;
  //         } else return pre - 1;
  //       });
  //     }, 1000);
  //   }

  //   if (audioRef.current?.currentTime.toFixed(1) >= track90) {
  //     var today = new Date();
  //     var date =
  //       today.getFullYear() +
  //       "-" +
  //       (today.getMonth() + 1) +
  //       "-" +
  //       today.getDate();
  //   }
  //   alert(audioRef.current);
  // }, [audioRef.current]);
  return (
    <div className="App">
      {/* <ReactPlayer
        style={{
          height: "100%",
          width: "100%",
        }}
        url="https://d3izw5wl1p5t0h.cloudfront.net/tracks/Five+Minute+Meditations/5+Minute+After+a+Long+Day.mp3"
      /> */}

      <img className="img" src={backgroundImage}></img>
      <div className="imgOverlay"></div>
      <div
        style={{
          position: "absolute",
        }}
      >
        <div className="spaceHeight5"></div>
        <div className="header">
          <img src={logo} className="App-logo" alt="logo" />
        </div>
        <div className="spaceHeight5"></div>
        <div className="header">
          <span className="headerTitle">{title}</span>
          <div className="spaceHeight5"></div>
          <span className="headerSubtitle">{substitle}</span>
        </div>
        <div className="spaceHeight200"></div>
        <div className="controllerOuterContainer">
          <img
            onClick={() => {
              audioRef.current.currentTime = audioRef.current?.currentTime - 15;
            }}
            src={Backward}
            className="App-logo"
            alt="logo"
          />
          <div className="spaceWidth3"></div>
          <div
            onClick={() => {
              setPlay((prevVal) => {
                if (prevVal == false) {
                  audioRef.current.play();
                  startTimer();
                } else {
                  audioRef.current.pause();
                  stopTimer();
                }
                return !prevVal;
              });
            }}
            className="controllerContainer"
          >
            {!play ? (
              <BsFillPlayFill size={"70px"} color="white" />
            ) : (
              <BsFillPauseFill size={"70px"} color="white" />
            )}
          </div>
          <div className="spaceWidth3"></div>

          <div>{duration}</div>
          <img
            onClick={() => {
              audioRef.current.currentTime = audioRef.current?.currentTime + 15;
            }}
            src={Forward}
            className="App-logo"
            alt="logo"
          />
        </div>
        {/* <ReactAudioPlayer
          src="https://d3izw5wl1p5t0h.cloudfront.net/tracks/Five+Minute+Meditations/5+Minute+After+a+Long+Day.mp3"
          autoPlay={true}
          controls
          muted={false}
          ref={player}
        /> */}
      </div>
    </div>
  );
}

export default App;
